<template>
  <invoices></invoices>
</template>

<script>
import Invoices from "@/components/company/invoices/Invoices";

export default {
  components: {
    Invoices
  }
};
</script>
