<template>
  <div class="flex items-center duration-100 cursor-pointer flex-col">
    <div
      class="flex mobile:flex-wrap w-full items-center px-3 py-5 hover:bg-offwhite hover:bg-opacity-50"
      @click="expanded = !expanded"
      :class="expanded ? 'border-b border-offwhite bg-offwhite' : ''"
    >
      <div class="w-3/12 mobile:w-9/12 flex break-all items-cente">
        <h4 class="truncate pr-2">{{ draft.title }}</h4>
      </div>
      <div class="w-3/12 mobile:w-9/12 mobile:hidden flex items-center pr-2">
        <div class="flex">
          <span
            class="typcn table-icon"
            :class="draft.client_is_company ? 'typcn-briefcase' : 'typcn-user'"
          ></span>
        </div>
        <span class="truncate pl-2">{{ draft.client_name }}</span>
      </div>
      <div class="w-2/12 mobile:w-3/12 flex items-center">
        <span class="typcn typcn-document-text table-icon mr-2"></span>
        <span>{{ draft.invoices.length }}</span>
      </div>
      <div class="w-3/12 mobile:w-full flex items-center mobile:hidden">
        <div class="flex mr-2">
          <div
            class="flex border-green border-2 rounded-full justify-center items-center h-7 w-7 leading-none"
          >
            <img src="/bc21/placeholder.svg" class="h-4 filter-to-green" />
          </div>
        </div>
        <div class="flex truncate">
          <span class="text-sm truncate">{{
            draft.location ? draft.location.long_address : "-"
          }}</span>
        </div>
      </div>
      <div
        class="w-1/12 duration-100 flex items-center justify-center mobile:hidden"
      >
        <span
          class="rounded-full typcn typcn-arrow-sorted-down transform duration-100"
          :class="expanded ? 'rotate-180' : ''"
        ></span>
      </div>
    </div>
    <div class="flex w-full h-1 bg-offwhite" v-if="!expanded"></div>
    <div class="flex flex-col w-full" v-if="expanded">
      <invoice
        v-for="item in status !== 0
          ? draft.invoices.filter(x => x.status === status)
          : draft.invoices"
        :key="item.id"
        :invoice="item"
        :draft="draft"
        @invoicesUpdated="handleInvoiceUpdate"
      ></invoice>
    </div>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";
import Invoice from "@/components/company/invoices/Invoice";

export default {
  name: "Draft",
  components: { Invoice },
  props: {
    draft: {
      type: Object,
      default: () => {
        return {};
      }
    },
    status: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  mixins: [StatusParser],
  computed: {
    draftStatus() {
      return this.parseProjectStatus(this.draft.status);
    }
  },
  methods: {
    handleInvoiceUpdate() {
      this.$parent.retrieveCompanyInvoices();
    }
  }
};
</script>
