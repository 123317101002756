<template>
  <div class="flex flex-col bg-white">
    <div class="flex items-center mb-4 mobile:flex-col">
      <h2 class="mr-4 mobile:mr-0 mobile:mb-4">Arved</h2>
      <div class="flex items-center justify-center mobile:mb-4">
        <div
          class="items-center flex flex-row cursor-pointer relative hover:bg-offwhite duration-50 mobile:mb-2"
          @click="statusMenuActive = !statusMenuActive"
          :class="statusMenuActive ? 'rounded-t-xl' : 'rounded-xl'"
        >
          <span v-if="status" v-html="parseDraftInvoiceStatus(status)" />
          <span v-if="!status" class="pl-4 pr-8 py-2"
            >Filtreeri staatuse järgi</span
          >
          <span class="typcn typcn-arrow-sorted-down mx-3"></span>
          <div class="status-menu mobile:z-10" v-if="statusMenuActive">
            <span
              v-for="statusItem in statusFilters"
              :key="statusItem.status"
              class="status-menu-item cursor-pointer"
              v-html="parseDraftInvoiceStatus(statusItem.status)"
              @click="filterInvoices(statusItem.status)"
            ></span>
          </div>
        </div>
      </div>
      <div class="ml-2 mobile:ml-0 mobile:mb-6">
        <button class="new-button-danger" v-if="status" @click="clearFilter">
          <span class="icon"
            ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
          /></span>
          <span class="label">Tühjenda</span>
        </button>
      </div>
    </div>

    <div class="flex flex-row mobile:flex-col">
      <div
        v-if="invoicesLoaded"
        class="flex w-full flex-col pr-5 mobile:pr-0 mobile:order-2"
      >
        <span
          v-if="projectWithInvoices.length === 0 && allInvoices.length === 0"
          >Arveid pole</span
        >
        <div
          class="flex flex-col items-center justify-center h-full w-full"
          v-if="projectWithInvoices.length === 0 && allInvoices.length !== 0"
        >
          <span>Selles staatuses arveid ei ole</span>
          <button @click="clearFilter" class="new-button-danger">
            <span class="label">Tühjenda filter</span>
          </button>
        </div>

        <draft
          v-for="draft in projectWithInvoices"
          :key="draft.uuid"
          :draft="draft"
          :status="status"
        ></draft>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import StatusParser from "@/assets/mixins/StatusParser";
import Draft from "@/components/company/invoices/Draft";
import EventBus from "@/assets/mixins/EventBus";

const statusFilters = [
  {
    text: "Aktiivne",
    status: 1
  },
  {
    text: "Käigus",
    status: 2
  },
  {
    text: "Probleem",
    status: 4
  },
  {
    text: "Lõpetatud",
    status: 5
  }
];

export default {
  name: "Invoices",
  components: { Draft },
  mixins: [RequestHandler, StatusParser],
  data() {
    return {
      invoicesLoaded: false,
      projectWithInvoices: [],
      allInvoices: [],
      status: 0,
      selectedFilter: null,
      statusFilters: statusFilters,
      statusMenuActive: false
    };
  },
  mounted() {
    this.retrieveCompanyInvoices();
    EventBus.$on("updateInvoices", () => {
      this.retrieveCompanyInvoices();
    });
  },
  beforeDestroy() {
    EventBus.$off("updateInvoices");
  },
  methods: {
    openNotificationInvoice() {
      if (Object.keys(this.$route.query).length > 0) {
        let draft = this.projectWithInvoices.find(
          x => x.uuid === this.$route.query.post_id
        );
        if (draft === undefined) {
          return;
        }
        let invoice = draft.invoices.find(
          y => y.id === Number(this.$route.query.invoice_id)
        );
        if (invoice === undefined) {
          return;
        }
        this.$store.dispatch("invoiceViewer/openInvoiceModal", {
          invoice: invoice,
          draft: draft
        });
      }
    },
    retrieveCompanyInvoices() {
      this.apiRequest(
        "notes/company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/invoices/",
        "get",
        true
      ).then(res => {
        this.projectWithInvoices = res.data;
        this.allInvoices = res.data;
        this.invoicesLoaded = true;
        this.openNotificationInvoice();
        if (this.status > 0) {
          this.filterInvoices(this.status);
        }
        if (this.$store.state.invoiceViewer.invoiceModal.show) {
          for (let i = 0; i < this.allInvoices.length; i++) {
            let invoiceToUse = this.allInvoices[i].invoices.find(
              x =>
                x.id ===
                this.$store.state.invoiceViewer.invoiceModal.data.invoice.id
            );
            if (invoiceToUse) {
              this.$store.state.invoiceViewer.invoiceModal.data.invoice = invoiceToUse;
              break;
            }
          }
        }
      });
    },
    clearFilter() {
      this.status = 0;
      this.projectWithInvoices = this.allInvoices;
    },
    filterInvoices(filterStatus) {
      this.invoicesLoaded = false;
      this.projectWithInvoices = [];
      this.status = filterStatus;
      for (let i = 0; i < this.allInvoices.length; i++) {
        if (
          this.allInvoices[i].invoices.filter(x => x.status === filterStatus)
            .length > 0
        ) {
          if (
            this.projectWithInvoices.find(
              y => y.uuid === this.allInvoices[i].uuid
            ) === undefined
          ) {
            this.projectWithInvoices.push(this.allInvoices[i]);
          }
        }
      }
      this.invoicesLoaded = true;
    }
  }
};
</script>

<style scoped lang="scss">
.co-filter-button {
  @apply flex items-center justify-center cursor-pointer duration-100 rounded-gwdfull shadow bg-offwhite text-darkgrey w-full p-2 mb-2;
  &.active {
    @apply bg-green cursor-default shadow text-white;
  }
  &:hover {
    @apply bg-darkgreen cursor-pointer shadow text-white;
  }
}
</style>
